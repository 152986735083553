import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import {
    VTButton,
    VTDialog,
    VTHomeDialogOption,
    VTTypography,
    VTTransferList,
    VTPublishedArchiveTable,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentSidePanel } from "../../store/slices/current.slice";
import { IUserInGroup } from "../../models/user.model";
import { SettingsRounded, PublishRounded, PersonRounded, DashboardRounded, Height } from "@mui/icons-material";
import VTImageDropper from "./VTImageDropper";
import { IScenarioOverview } from "@virtus-tech-repository/virtus-tech-repository/lib/models/scenario.model";
import { useGetScenarioAccessQuery, useUpdateScenarioAccessMutation } from "../../services/scenarioAccess.service";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { EOrganisation } from "../../models/organisations.model";
import HomeSettingsItem from "./HomeSettingsItem";
import WordingHelper from "../../helpers/wordingHelper";
import { usePublishScenarioMutation } from "../../services/scenario.service";
import moment from "moment";
import Joyride, { CallBackProps, STATUS } from "react-joyride";
import HomeScenarioDialogJoyride from "./HomeScenarioDialogJoyride";
import {
    HOME_PAGE_OVERVIEW_TUTORIAL_DONE,
    SCENARIO_PUBLISHING_TUTORIAL_DONE,
} from "../../utils/joyRideTutorialsLocalStorageStrings";

interface IProps {
    scenarioDialog: IScenarioOverview | undefined;
    setScenarioDialog: Dispatch<SetStateAction<undefined | IScenarioOverview>>;
    groups: any;
    currentPreviewImage: string;
    setCurrentPreviewImage: (currentPreviewImage: string) => void;
    scenarioLoading: string;
    setScenarioLoading: (scenarioLoading: string) => void;
    createScenario: any;
    updateScenario: any;
    scenarioDialogFirstView: boolean;
    setScenarioDialogFirstView: (scenarioDialogFirstView: boolean) => void;
    activeStep: number;
    setActiveStep: (activeStep: number) => void;
}

export default function HomeScenarioDialog({
    scenarioDialog,
    setScenarioDialog,
    groups,
    currentPreviewImage,
    setCurrentPreviewImage,
    scenarioLoading,
    setScenarioLoading,
    createScenario,
    updateScenario,
    scenarioDialogFirstView,
    setScenarioDialogFirstView,
    activeStep,
    setActiveStep,
}: IProps) {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const { id: userId } = useAppSelector((state) => state.userReducer);
    const { currentSidePanel, currentSidePannelDesiredWidth } = useAppSelector((state) => state.currentReducer);
    const { organisation } = useAppSelector((state) => state.userReducer);
    const [updateScenarioAccess] = useUpdateScenarioAccessMutation();
    const [publishScenario] = usePublishScenarioMutation();

    const [currentTitle, setCurrentTitle] = useState<string>("");
    const [currentDescription, setCurrentDescription] = useState<string>("");
    const [currentAdmins, setCurrentAdmins] = useState<IUserInGroup[]>([]);
    const [currentViewers, setCurrentViewers] = useState<IUserInGroup[]>([]);
    const [currentOwner, setCurrentOwner] = useState<string>("");

    const [settingsDeviceMode, setSettingsDeviceMode] = useState<boolean>(false);
    const [settingsDataCapture, setSettingsDataCapture] = useState<boolean>(false);
    const [settingsDisplayScore, setSettingsDisplayScore] = useState<boolean>(false);

    const [publishDialog, setPublishDialog] = useState<boolean>(false);
    const [firstViewing, setFirstViewing] = useState<boolean>(false);
    const [publishedSelected, setPublishedSelected] = useState<string>("");

    const { data: scenarioAccess } = useGetScenarioAccessQuery(scenarioDialog ? scenarioDialog.id : "");

    const iconStyles = {
        background: theme.palette.primary.main,
        borderRadius: "50%",
        padding: "4px",
        color: "#fff",
        "&:hover": {
            background: theme.palette.primary.dark,
        },
    };

    useEffect(() => {
        if (scenarioDialog) {
            setFirstViewing(false);

            if (scenarioDialog.title === "") {
                setFirstViewing(true);
            }

            const adminUsers: IUserInGroup[] = scenarioDialog.admins.map((group: any) => {
                return {
                    id: group,
                    title: group.split("_")[1].replace(/-/g, " "),
                    role: "admin",
                };
            });
            const viewerUsers: IUserInGroup[] = scenarioDialog.viewers.map((group: any) => {
                return {
                    id: group,
                    title: group.split("_")[1].replace(/-/g, " "),
                    role: "viewer",
                };
            });
            setCurrentTitle(scenarioDialog.title);
            setCurrentDescription(scenarioDialog.description);
            setCurrentPreviewImage(scenarioDialog.previewImage);
            setCurrentOwner(scenarioDialog.owner);
            setActiveStep(1);
            setRunJoyrideOverview(
                localStorage.getItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE) &&
                    localStorage.getItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE) === "true"
                    ? false
                    : true,
            );

            if (scenarioDialog.settings) {
                setSettingsDeviceMode(scenarioDialog.settings.vrEnabled);
                setSettingsDataCapture(scenarioDialog.settings.dataCapture);
                setSettingsDisplayScore(scenarioDialog.settings.displayStudentScore);
            }

            //@ts-ignore
            if (scenarioDialog.published?.currentPublishedId) {
                //@ts-ignore
                setPublishedSelected(scenarioDialog.published?.currentPublishedId);
            }
        }
    }, [scenarioDialog]);

    useEffect(() => {
        if (scenarioAccess) {
            let tempAdminArray: IUserInGroup[] = [];
            scenarioAccess.forEach((group: any) => {
                tempAdminArray.push({ id: group.id, title: group.title, role: group.role });
            });
            setCurrentAdmins(tempAdminArray);
        }
    }, [scenarioAccess]);

    const [runJoyrideOverview, setRunJoyrideOverview] = useState<boolean>(false);
    const [runJoyrideCreators, setRunJoyrideCreators] = useState<boolean>(false);
    const [runJoyrideSettings, setRunJoyrideSettings] = useState<boolean>(false);
    const [runJoyridePublish, setRunJoyridePublish] = useState<boolean>(false);

    return (
        <VTDialog
            disableEnforceFocus={true}
            title={organisation === EOrganisation.ECO_GOAL ? "Blog Overview" : "Scenario Overview"}
            sx={{ marginLeft: currentSidePanel.currentWidth / 8 + 5, zIndex: 5, marginTop: "70px" }}
            open={scenarioDialog !== undefined}
            handleClose={() => {
                setScenarioDialogFirstView(false);
                setScenarioDialog(undefined);
                dispatch(
                    setCurrentSidePanel({
                        currentTab: "Routes",
                        currentWidth: 200,
                        option: undefined,
                    }),
                );

                setCurrentPreviewImage("");
            }}
            primaryArea={
                <>
                    {/*---------------------------------------------------------------------------------*/}
                    {/* Dialog cancel button */}
                    <VTButton
                        color={"secondary"}
                        sx={
                            {
                                // width: "200px",
                                // height: "50px",
                                // marginRight: "30px",
                            }
                        }
                        onClick={() => {
                            setScenarioDialogFirstView(false);
                            setScenarioDialog(undefined);
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: "Routes",
                                    currentWidth: 200,
                                    option: undefined,
                                }),
                            );
                            setCurrentPreviewImage("");
                        }}
                    >
                        Cancel
                    </VTButton>

                    {/*---------------------------------------------------------------------------------*/}
                    {/* Save the scenario data changes */}
                    {publishDialog ? (
                        <>
                            {/* <VTButton
                                sx={{ height: "50px", width: "230px" }}
                                onClick={() => {
                                    handleSaveScenario(
                                        currentTitle,
                                        currentDescription,
                                        currentPreviewImage,
                                        currentAdmins,
                                        currentViewers,
                                        currentOwner,
                                    );
                                    if (scenarioDialog) {
                                        publishScenario(scenarioDialog!.id);
                                    }
                                }}
                            >
                                {scenarioDialog && scenarioDialog.published ? "Re-Publish" : "Publish"}
                                <PublishRounded sx={{ marginLeft: "5px", marginTop: "-3px" }} />
                            </VTButton> */}
                        </>
                    ) : (
                        <Tooltip
                            title={"Please set a title to save the scenario"}
                            disableHoverListener={currentTitle !== ""}
                        >
                            <div>
                                <VTButton
                                    sx={{
                                        marginLeft: "5px",
                                    }}
                                    onClick={() => {
                                        if (scenarioDialogFirstView && scenarioDialog) {
                                            setScenarioLoading("CreateScenario");
                                            setScenarioDialogFirstView(false);
                                        }
                                        createScenario({
                                            ...scenarioDialog,
                                            title: currentTitle,
                                            description: currentDescription,
                                            previewImage: currentPreviewImage,
                                            scenes: [],
                                            settings: {
                                                vrEnabled: settingsDeviceMode,
                                                dataCapture: settingsDataCapture,
                                                displayStudentScore: settingsDisplayScore,
                                            },
                                        });

                                        if (currentAdmins.length > 0) {
                                            updateScenarioAccess({
                                                scenarioId: scenarioDialog!.id,
                                                accessList: [
                                                    ...currentAdmins.map((userElement: IUserInGroup) => userElement.id),
                                                ],
                                            });
                                        } else {
                                            updateScenarioAccess({
                                                scenarioId: scenarioDialog!.id,
                                                accessList: [userId],
                                            });
                                        }
                                        if (runJoyrideSettings) {
                                            localStorage.setItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE, "true");
                                        }

                                        setScenarioDialog(undefined);

                                        dispatch(
                                            setCurrentSidePanel({
                                                currentTab: "Routes",
                                                currentWidth: 200,
                                                option: undefined,
                                            }),
                                        );
                                    }}
                                    disabled={currentTitle === ""}
                                    className={"saveButton"}
                                >
                                    Save
                                </VTButton>
                            </div>
                        </Tooltip>
                    )}
                </>
            }
        >
            {/*------------------------------------------------------------------------------------*/}
            {/* Content Wrapper */}

            <Grid container sx={{ height: "100%", width: "100%" }}>
                {organisation === "virtus-tech" && (
                    <HomeScenarioDialogJoyride
                        runJoyrideOverview={runJoyrideOverview}
                        setRunJoyrideOverview={setRunJoyrideOverview}
                        runJoyrideCreators={runJoyrideCreators}
                        setRunJoyrideCreators={setRunJoyrideCreators}
                        runJoyrideSettings={runJoyrideSettings}
                        setRunJoyrideSettings={setRunJoyrideSettings}
                        runJoyridePublish={runJoyridePublish}
                        setRunJoyridePublish={setRunJoyridePublish}
                    />
                )}
                <Grid item container justifyContent={"center"} alignContent={"center"} sx={{ width: "100px" }}>
                    <VTButton
                        type="list"
                        onClick={() => setActiveStep(1)}
                        sx={{
                            marginTop: "10px",
                            height: "100%",
                            borderRadius: "15px 15px 0 0",
                            backgroundColor: activeStep === 1 ? "#444" : "#333",
                            ":hover": { backgroundColor: "#444" },
                        }}
                    >
                        <Grid container alignContent={"center"} justifyContent={"center"}>
                            <DashboardRounded sx={{ fontSize: "1.5em" }} /> Overview
                        </Grid>
                    </VTButton>

                    <VTButton
                        type="list"
                        onClick={() => {
                            setActiveStep(2);
                            if (
                                localStorage.getItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE) &&
                                localStorage.getItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE) === "true"
                                    ? false
                                    : true
                            ) {
                                setRunJoyrideOverview(false);
                                setRunJoyrideCreators(true);
                            }
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: undefined,
                                    currentWidth: 0,
                                    option: undefined,
                                }),
                            );
                        }}
                        sx={{
                            height: "100%",
                            backgroundColor: activeStep === 2 ? "#444" : "#333",
                            borderRadius: 0,
                            ":hover": { backgroundColor: "#444" },
                        }}
                        className="creatorButton"
                    >
                        <Grid container alignContent={"center"} justifyContent={"center"}>
                            <PersonRounded sx={{ fontSize: "1.5em" }} /> Creators
                        </Grid>
                    </VTButton>
                    <VTButton
                        type="list"
                        onClick={() => {
                            setActiveStep(4);
                            if (
                                localStorage.getItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE) &&
                                localStorage.getItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE) === "true"
                                    ? false
                                    : true
                            ) {
                                setRunJoyrideCreators(false);
                                setRunJoyrideSettings(true);
                            }
                            dispatch(
                                setCurrentSidePanel({
                                    currentTab: undefined,
                                    currentWidth: 0,
                                    option: undefined,
                                }),
                            );
                        }}
                        sx={{
                            height: "100%",
                            borderRadius: firstViewing ? "0 0 15px 15px" : 0,
                            backgroundColor: activeStep === 4 ? "#444" : "#333",
                            ":hover": { backgroundColor: "#444" },
                        }}
                        className="settingsButton"
                    >
                        <Grid container alignContent={"center"} justifyContent={"center"}>
                            <SettingsRounded sx={{ fontSize: "1.5em" }} /> Settings
                        </Grid>
                    </VTButton>
                    {!firstViewing && (
                        <VTButton
                            type="list"
                            onClick={() => {
                                setActiveStep(3);
                                if (
                                    localStorage.getItem(SCENARIO_PUBLISHING_TUTORIAL_DONE) &&
                                    localStorage.getItem(SCENARIO_PUBLISHING_TUTORIAL_DONE) === "true"
                                        ? false
                                        : true
                                ) {
                                    setRunJoyridePublish(true);
                                }
                                dispatch(
                                    setCurrentSidePanel({
                                        currentTab: undefined,
                                        currentWidth: 0,
                                        option: undefined,
                                    }),
                                );
                            }}
                            sx={{
                                height: "100%",
                                backgroundColor: activeStep === 3 ? "#444" : "#333",
                                ":hover": { backgroundColor: activeStep === 3 ? "#444" : "#444" },
                                borderRadius: "0 0 15px 15px",
                            }}
                        >
                            <Grid container alignContent={"center"} justifyContent={"center"}>
                                <PublishRounded sx={{ fontSize: "1.5em" }} /> Publish
                            </Grid>
                        </VTButton>
                    )}
                </Grid>
                {activeStep === 1 && (
                    <Grid
                        container
                        justifyContent={"center"}
                        spacing={"5px"}
                        sx={{ padding: "20px", width: "calc(100% - 100px)" }}
                    >
                        {/*---------------------------------------------------------------------------------*/}
                        {/* Content Left */}
                        {/*---------------------------------------------------------------------------------*/}
                        <Grid xs={12} md={6} container item justifyContent={"center"} className={"overview"}>
                            {/*-------------------------------------------------------------------------------*/}
                            {/* Maps the title */}
                            <Grid item xs={12} container justifyContent={"center"}>
                                <VTHomeDialogOption
                                    value={currentTitle}
                                    setValue={setCurrentTitle}
                                    title={"Title"}
                                    lines={2}
                                />
                            </Grid>

                            {/*-------------------------------------------------------------------------------*/}
                            {/* Maps the description  */}
                            <Grid item xs={12} container justifyContent={"center"}>
                                <VTHomeDialogOption
                                    value={currentDescription}
                                    setValue={setCurrentDescription}
                                    title={"Description"}
                                    lines={6}
                                />
                            </Grid>
                        </Grid>

                        {/*--------------------------------------------------------------------------------*/}
                        {/* Content Right */}
                        {/*--------------------------------------------------------------------------------*/}
                        <Grid
                            xs={12}
                            md={6}
                            container
                            item
                            justifyContent={"center"}
                            sx={{ marginTop: "10px" }}
                            className={"overview2"}
                        >
                            {/*-------------------------------------------------------------------------------*/}
                            {/* Maps the Admins and viewer user fields  */}

                            {/*-------------------------------------------------------------------------------*/}
                            {/* Preview image */}

                            <VTImageDropper
                                currentImage={currentPreviewImage}
                                setCurrentImage={setCurrentPreviewImage}
                                droppableId={EDroppableContainerId.SCENARIO_PREVIEW_IMAGE_DROP}
                            />
                            <Grid
                                xs={12}
                                container
                                item
                                justifyContent={"center"}
                                alignItems={"center"}
                                marginBottom={"5px"}
                            >
                                <Typography>Drop and drag an image from the image sidebar</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {activeStep === 2 && (
                    <Grid
                        container
                        justifyContent={"center"}
                        sx={{ padding: "20px", width: "calc(100% - 100px)" }}
                        className={"creators"}
                    >
                        <VTTypography align="center" sx={{ width: "100%", marginBlock: "20px" }}>
                            Assign Groups
                        </VTTypography>

                        <VTTransferList
                            list={
                                groups && groups.length > 0
                                    ? groups.filter((group: IUserInGroup) => {
                                          if (group.role === "admin") {
                                              if (currentAdmins.length) {
                                                  return currentAdmins.some((admin) => {
                                                      return admin.id !== group.id;
                                                  });
                                              } else {
                                                  return group;
                                              }
                                          }
                                      })
                                    : [
                                          {
                                              id: "No groups assigned, they are not required to complete this demo. Contact us to collaborate with other creators",
                                              title: "No groups assigned, they are not required to complete this demo. Contact us to collaborate with other creators",
                                              role: "admin",
                                          },
                                      ]
                            }
                            disabled={groups.length === 0}
                            selectedList={currentAdmins}
                            setSelectedList={setCurrentAdmins}
                            titlePropertyName="title"
                        />
                    </Grid>
                )}
                {activeStep === 3 && (
                    <Grid container sx={{ padding: "20px", width: "calc(100% - 100px)" }}>
                        <Grid item xs={12}>
                            <VTTypography align="center" type="Subtitle1" sx={{ width: "100%", marginBlock: "20px" }}>
                                Publish Scenario
                            </VTTypography>
                        </Grid>

                        {/* @ts-ignore */}
                        {scenarioDialog!.published?.publishedArchive.length > 0 ? (
                            <Grid item xs={6} container justifyContent={"center"} sx={{ padding: "5px" }}>
                                <Grid item xs={12} sx={{ height: "250px", overflowY: "auto" }}>
                                    {/* @ts-ignore */}
                                    {scenarioDialog!.published &&
                                        //@ts-ignore
                                        scenarioDialog!.published?.publishedArchive &&
                                        //@ts-ignore
                                        scenarioDialog!.published?.publishedArchive.length > 0 && (
                                            <>
                                                <VTPublishedArchiveTable
                                                    tableData={[
                                                        //@ts-ignore
                                                        ...scenarioDialog!.published?.publishedArchive.map(
                                                            (element: any) => {
                                                                return {
                                                                    id: element.publishedId,
                                                                    lastAttempt: moment(element.publishedDate),
                                                                };
                                                            },
                                                        ),
                                                    ]}
                                                    onRowSelected={(e: any) => {
                                                        setPublishedSelected(e.id);
                                                    }}
                                                    selectedId={[publishedSelected]}
                                                />
                                            </>
                                        )}
                                </Grid>
                                <Grid item xs={6}>
                                    <VTTypography
                                        align="center"
                                        sx={{ width: "100%", marginTop: "10px", marginLeft: "10px" }}
                                    >
                                        Last Published: {moment(scenarioDialog!.published?.publishedDate).fromNow()}
                                    </VTTypography>
                                </Grid>
                                <Grid item xs={6}>
                                    <VTTypography align="center" sx={{ width: "100%", marginTop: "10px" }}>
                                        Published Version: {/* @ts-ignore */}
                                        {scenarioDialog!.published?.publishedArchive &&
                                            //@ts-ignore
                                            scenarioDialog!.published?.publishedArchive.length}
                                    </VTTypography>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={6} container justifyContent={"center"}>
                                <VTTypography type="Heading2" align="center">
                                    No current published versions
                                </VTTypography>{" "}
                                <VTTypography type="Heading4" align="center">
                                    To make avalible for viewers please publishe the scenario
                                </VTTypography>
                            </Grid>
                        )}

                        <Grid
                            item
                            xs={6}
                            container
                            justifyContent={"center"}
                            alignContent={"flex-start"}
                            sx={{ padding: "5px" }}
                        >
                            <Tooltip
                                title={"Unpublish the scenario so it is no longer avalible for viewers"}
                                placement="top"
                            >
                                <Grid item>
                                    {scenarioDialog!.published?.published && (
                                        <VTButton
                                            sx={{
                                                height: "50px",
                                                marginRight: "25px",
                                                marginBottom: "15px",
                                                width: "300px",
                                            }}
                                            onClick={() => {
                                                createScenario({
                                                    ...scenarioDialog,
                                                    title: currentTitle,
                                                    description: currentDescription,
                                                    previewImage: currentPreviewImage,
                                                    scenes: [],
                                                    settings: {
                                                        vrEnabled: settingsDeviceMode,
                                                        dataCapture: settingsDataCapture,
                                                        displayStudentScore: settingsDisplayScore,
                                                    },
                                                    published: { ...scenarioDialog?.published, published: false },
                                                });

                                                if (currentAdmins.length > 0) {
                                                    updateScenarioAccess({
                                                        scenarioId: scenarioDialog!.id,
                                                        accessList: [
                                                            ...currentAdmins.map(
                                                                (userElement: IUserInGroup) => userElement.id,
                                                            ),
                                                        ],
                                                    });
                                                } else {
                                                    updateScenarioAccess({
                                                        scenarioId: scenarioDialog!.id,
                                                        accessList: [userId],
                                                    });
                                                }
                                                if (runJoyrideSettings) {
                                                    localStorage.setItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE, "true");
                                                }

                                                setScenarioDialog(undefined);

                                                dispatch(
                                                    setCurrentSidePanel({
                                                        currentTab: "Routes",
                                                        currentWidth: 200,
                                                        option: undefined,
                                                    }),
                                                );
                                            }}
                                            color="secondary"
                                        >
                                            Unpublish Scenario
                                        </VTButton>
                                    )}
                                </Grid>
                            </Tooltip>

                            <Tooltip
                                title={
                                    "Change the current published version for the archive version to be displayed for the viewers"
                                }
                                placement="left"
                            >
                                <Grid item>
                                    {/* @ts-ignore */}
                                    {(scenarioDialog!.published?.currentPublishedId !== publishedSelected ||
                                        !scenarioDialog!.published?.published) &&
                                        //  @ts-ignore
                                        scenarioDialog!.published?.publishedArchive.length > 0 &&
                                        //  @ts-ignore
                                        scenarioDialog!.published?.currentPublishedId !== "" && (
                                            <VTButton
                                                sx={{
                                                    height: "50px",
                                                    marginRight: "25px",
                                                    marginBottom: "15px",
                                                    width: "300px",
                                                }}
                                                onClick={() => {
                                                    createScenario({
                                                        ...scenarioDialog,
                                                        title: currentTitle,
                                                        description: currentDescription,
                                                        previewImage: currentPreviewImage,
                                                        scenes: [],
                                                        settings: {
                                                            vrEnabled: settingsDeviceMode,
                                                            dataCapture: settingsDataCapture,
                                                            displayStudentScore: settingsDisplayScore,
                                                        },
                                                        published: {
                                                            ...scenarioDialog?.published,
                                                            published: true,
                                                            publishedDate: new Date().toISOString(),
                                                            currentPublishedId: publishedSelected,
                                                        },
                                                    });

                                                    if (currentAdmins.length > 0) {
                                                        updateScenarioAccess({
                                                            scenarioId: scenarioDialog!.id,
                                                            accessList: [
                                                                ...currentAdmins.map(
                                                                    (userElement: IUserInGroup) => userElement.id,
                                                                ),
                                                            ],
                                                        });
                                                    } else {
                                                        updateScenarioAccess({
                                                            scenarioId: scenarioDialog!.id,
                                                            accessList: [userId],
                                                        });
                                                    }
                                                    if (runJoyrideSettings) {
                                                        localStorage.setItem(HOME_PAGE_OVERVIEW_TUTORIAL_DONE, "true");
                                                    }

                                                    setScenarioDialog(undefined);

                                                    dispatch(
                                                        setCurrentSidePanel({
                                                            currentTab: "Routes",
                                                            currentWidth: 200,
                                                            option: undefined,
                                                        }),
                                                    );
                                                }}
                                                color="secondary"
                                            >
                                                Re-Publish Archive Scenario
                                            </VTButton>
                                        )}
                                </Grid>
                            </Tooltip>
                            <Tooltip title={"View and edit the published version"} placement="left">
                                <Grid item>
                                    {publishedSelected && (
                                        <VTButton
                                            sx={{
                                                height: "50px",
                                                marginRight: "25px",
                                                marginBottom: "15px",
                                                width: "300px",
                                            }}
                                            onClick={() => {
                                                window.open(`/creator/${publishedSelected}`);
                                            }}
                                            color="secondary"
                                        >
                                            View Published Version
                                        </VTButton>
                                    )}
                                </Grid>
                            </Tooltip>
                            <Tooltip title={"Publish new version based on the current scenario"}>
                                <Grid item>
                                    <VTButton
                                        sx={{ height: "50px", marginRight: "25px", width: "300px" }}
                                        onClick={() => {
                                            // publishScenario(scenarioDialog!.id);
                                            dispatch(
                                                setCurrentSidePanel({
                                                    currentTab: "Routes",
                                                    currentWidth: 200,
                                                    option: undefined,
                                                }),
                                            );

                                            publishScenario({
                                                id: scenarioDialog!.id,
                                                published: true,
                                            });

                                            setScenarioDialog(undefined);
                                        }}
                                    >
                                        Publish new Version
                                        <PublishRounded sx={{ marginLeft: "5px", marginTop: "-5px" }} />
                                    </VTButton>
                                </Grid>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
                {activeStep === 4 && (
                    <Grid
                        container
                        sx={{
                            width: "calc(100% - 100px)",
                            height: "350px",
                            alignItems: "center",
                            display: "flex",
                        }}
                    >
                        <Box
                            component="div"
                            sx={{
                                height: "210px",
                                paddingLeft: "20px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}
                        >
                            <HomeSettingsItem
                                title={"Device Mode"}
                                value={settingsDeviceMode}
                                setValue={setSettingsDeviceMode}
                                info={"Some features are only available on desktop mode"}
                                heading1="Desktop"
                                heading2="VR Enabled"
                                className={"settingItem1"}
                            />

                            <HomeSettingsItem
                                title={"Data Capture"}
                                value={settingsDataCapture}
                                setValue={setSettingsDataCapture}
                                info={"Capture user results and performance tracking"}
                                heading1="No Capture"
                                heading2="Capture"
                                className={"settingItem2"}
                            />
                            <HomeSettingsItem
                                title={"Display Score"}
                                value={settingsDisplayScore}
                                setValue={setSettingsDisplayScore}
                                info={"Give the user a estimate of there results if mark have been assigned"}
                                heading1="Hide Results"
                                heading2="Show Results"
                                className={"settingItem3"}
                            />
                        </Box>
                    </Grid>
                )}
            </Grid>
        </VTDialog>
    );
}
